.modal-upgrade {
    width: 390px;
}

.modal-upgrade .modal-header {
    text-align: center !important;
    border-bottom: none;
    padding: 16px;
}

.modal-upgrade .modal-container {
    padding: 0px 16px;
}

.plan-desc-container {
    background: #E6EEF2;
    padding: 14px;
    font-size: 14px;
    border-radius: 16px;
}

.plan-desc-container li {
    position: relative;
    padding-bottom: 4px;
}

.plan-desc-container .plan-desc-title {
    color: #787878;
}

.plan-desc-container .plan-desc-content {
    display: inline;
    position: absolute;
    right: 0;
    top: 0;
}

.plan-desc-content.red {
    color: #FF5757;
}

.plan-generation-guide {
    padding-top: 8px;
    padding-bottom: 16px;
    color: #787878;
    font-size: 14px;
}

.ok-button.deactivate {
    width: 100%;
    margin: 24px 0px;
    background: #FF5757;
}
