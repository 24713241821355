.navbar {
    position: absolute;
    width: 100vw;
    padding-top: 24px;
    padding-bottom: 24px;
    
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
    border-radius: 8px;
}

.navbar:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.navbar .logo {
    position: absolute;
    width: 88px;
    height: 51px;

    font-family: Futura;
    line-height: 150%;
    /* identical to box height, or 51px */

    display: flex;
    align-items: center;

    color: #111111;
}

@media (max-width: 484px) {
    .navbar {
	padding-left: 8px;
	padding-right: 8px;
    }
}

