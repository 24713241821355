.container-gallery .my-plan-container h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: black;
    text-align: left;
    margin: 0;
}

.container-gallery .my-plan-container a {
    font-style: normal;
    color: #777777;
    font-size: 14px;
}

.container-gallery .my-plan-container .my-plan-title {
    padding-top: 10px;
    padding-bottom: 10px;
}


.container-gallery .my-plan-container .plan-name {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 2px solid #E5EDF2;
}

.container-gallery .plans-card {
    margin-bottom: 16px;
}

.container-gallery .my-plan-card {
    border: 2px solid #E5EDF2;
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
    background: white;
}

.container-gallery .my-reward-card {
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
}

.container-gallery .my-reward-card p {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #777777;
    text-align: left;
    margin: 0;
}

.my-plan-container .plan-name {
    text-align: center !important;
}

.container-gallery .my-reward-card .reveal-button {
    position: absolute;
    top: 12px;
    right: 0px;
    width: 100px;
    height: 36px;
    background: linear-gradient(94.25deg, #FF006E 0%, #3A86FF 100%);
    border-radius: 53px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    /* or 25px */

    text-align: center;
    letter-spacing: 0.15px;
    text-transform: uppercase;

    color: #FFFFFF;
}


.container-gallery .upgrade-plan-card {
    position: relative;
    border: 3px solid transparent;
    border-radius: 16px;
    background-image: linear-gradient(#FFFFFF, #FFFFFF),
		      linear-gradient(to right, #FF006E 0%, #3A86FF 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    position: relative;
    margin-bottom: 16px;
}

.container-gallery .upgrade-plan-card.disabled {
    background-image: linear-gradient(#E5EDF2, #E5EDF2),
		      linear-gradient(to right, #777777 0%, #777777 100%);
}

.container-gallery .upgrade-plan-card .content {
    padding: 24px;
}

.container-gallery .upgrade-plan-card p {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #777777;
    text-align: left;
    margin: 0;
}

.upgrade-plan-card i {
    position: absolute;
    top: 44px;
    right: 16px;
}
