.plan-sliding-menu .sliding-title {
    position: relative;
    padding-top: 20px;
    padding-bottom: 10px;
}

.plan-sliding-menu .sliding-title a {
    position: absolute;
    right: 0;
}

.plan-sliding-menu .sliding-content.open {
    visibility: visible;
}

.plan-sliding-menu .sliding-content.close {
    display: none;
}

.how-it-works {
    height: 200px;
    overflow-y: scroll;
}

.how-it-works li {
    padding-left: 8px;
}

.how-it-works ul {
    list-style-type: disc;
}
