.main-card {
    display: table;
    margin: 30px auto 10px;
    width: 484px;
    background: inherit;
    background: rgba(255, 255, 255, 0.76);
    backdrop-filter: blur(40px);
    border-radius: 24px;
    padding-top: var(--p1);
    padding-left: var(--p1);
    padding-right: var(--p1);
    padding-bottom: var(--p1);
    font-size: var(--s2);
    font-weight: bold;
}

.main-card h1 {
    font-size: var(--s1);
    font-weight: var(--w1);
    margin-bottom: 4px;
    text-align: center;
}

.main-card .logo {
    height: 45px;
    margin-top: 15px;
    margin-bottom: 22px;
    background: url("images/logo.png") left top;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.main-card h3 {
    font-size: var(--s3);
    font-weight: var(--w2);
    margin-bottom: 16px;
}

.main-card h2 {
    font-size: var(--s2);
    font-weight: var(--w2);
    padding: 0;
    margin: 0;
    text-align: center;
}

.main-card h4 {
    font-size: var(--s4);
    font-weight: var(--w3);
    margin-bottom: 4px;
}

.main-card .row {
    padding-top: var(--p1);
    padding-bottom: var(--p1);
}

.main-card .block-title {
    display: inline-block;
    width: 60%;
    line-height: 140%;
    color: grey;
    font-size: 16px;
}

.main-card .block-title span {
    font-size: 14px;
}

.main-card .block-number {
    display: inline-block;
    vertical-align:top;
    margin-top: 10px;
    margin-right: 10px;
    width: 24px;
    height: 24px;
    background: #ccc;
    color: white;
    -moz-border-radius: 70px; 
    -webkit-border-radius: 70px; 
    border-radius: 70px;
}

.main-card .block-content {
    display: inline-block;
    padding: 4px;
    width: 30%;
    vertical-align:top;
    margin-top: 10px;
    font-size: 14px;
    background: #ccc;
    color: white;
    -moz-border-radius: 70px; 
    -webkit-border-radius: 70px; 
    border-radius: 70px;    
}

.main-card .block-content.current {
    background: #3A86FF;
}

.main-card .block-number.current {
    background: black;
}

.main-card .block-title.current {
    color: black;
}

.main-card .block-line {
    margin-top: 10px;
}

.main-card hr {
    margin: 0;
}

.box-title {
    display: inline-block;
    width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.box-content {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;    
}

.logo.navbar-brand {
    width: 88px;
    height: 51px;
    background: url("images/logo.png") left top;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.ic-klaytn {
    background: url("images/klaytn.png") left top; 
    width: var(--s2);
    height: var(--s2);
    margin-top: 0px;
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: var(--s5);
}

.ic-login {
    background: url("images/login.png") left top; 
    width: var(--s2);
    height: var(--s2);
    margin-top: 0px;
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: var(--s5);
}

.main-card .ic-klaytn {
    margin-top: 1px;
}

.other-info {
    font-size: 14px;
    color: #000;
    text-align: center;
}

.other-info a {
    color: #000;
}

.container-captcha {
    margin-top: 20px;
    margin-bottom: 20px;
}
.container-captcha div {
    margin: auto;
}

.no-kaikas {
    text-align: center;
    padding: 60px;
}

.toasts-container {
    /* display: block; */
    /* width: 100px; */
    /* height: 50px; */
}

.toasts-container .toast-success {
    display: inline-block;
    font-size: 25px;
    /*     /\* width: 100px !important; *\/ */
    /*     /\* height: 50px !important; *\/ */
}

.toasts-container .toast-error {
    display: inline-block;
    font-size: 25px;
    /*     /\* width: 100px !important; *\/ */
    /*     /\* height: 50px !important; *\/ */
}



.react-numeric-input {
    font-size: var(--s1);
    font-weight: var(--w1);
    margin-top: 36px;
    margin-bottom: var(--p4);
}

.react-numeric-input b {
    width: 40px !important;
    height: 40px;

    background: #1B1B1B !important;
    border-radius: 8px;
}

.react-numeric-input i {
    background: #fff !important;
}

.react-numeric-input input {
    background: none;
    border: none;
    font-weight: var(--w1);
}

.btn-mint {
    width: 100%;
    background: linear-gradient(94.25deg, #FF006E 0%, #3A86FF 100%);
    border-radius: 16px;
    font-size: var(--s1);
    font-weight: var(--w3);
    height: 54px;
    margin-bottom: var(--p5);
}

.btn-mint.ready {
    background: #B6BEDB;
    border: 0;
}

.mint-title {
    position: relative;
}

.mint-title h3 {
    position: absolute;
    left: 0;
}

.mint-desc {
    position: absolute;
    right: 0;
    margin-top: 2px;
    margin-bottom: 0px;
    height: var(--s3);
}

.line-balance {
    text-align: center;
    font-size: var(--s4)
}

.line-balance span {
    color: #555555;
    font-weight: var(--w2);
}

.circle-progress {
    position: absolute;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, 10%);
    text-align: center;
    z-index: 10;
}

h3 span {
    color: #888;
    font-size: 12px;
    font-weight: var(--w3);
}

.content.size-small {
    color: #888;
    font-size: 12px;
    font-weight: var(--w3);
}



.btn-klaytn-wallet {
    width: 232px;
    font-size: 16px;
    z-index: 1;
    background: #fff;
    line-height: 140%;
    font-weight: var(--w2);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 16px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);    
}

.btn-login {
    font-size: 16px;
    z-index: 1;
    background: #fff;
    line-height: 140%;
    font-weight: var(--w2);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 16px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);    
}

.btn-select-wallet {
    padding: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
    border-radius: 16px;
    border: 1px solid #DBDBDB;
    box-sizing: border-box;
}

.btn-select-wallet .ic-kaikas {
    position: absolute;
    left: 35%;
}

.btn-select-wallet .ic-metamask {
    position: absolute;
    left: 30%;
}

.btn-select-wallet .ic-klip {
    position: absolute;
    left: 37%;
}

.modal-wallets {
    font-size: 16px;
    font-weight: 700;    
}

.modal-wallets ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.modal-wallets li a {
    display: block;
    color: #000000;
    text-decoration: none;
    padding-left:15px;
}

.modal-wallets .modal-header {
    display: block;
    text-align: center;
}

.modal-wallets .modal-header h3 {
    font-size: 20px;
    font-weight: 700;
}

.modal-wallets .modal-header p {
    font-size: 14px;
    font-weight: 500;
}

.modal-wallets .modal-header img {
    width: 80px;
    height: 80px;
    border-radius: 16px;
    margin: 16px;
}

.modal-wallets p {
    display: block;
    color: rgb(112, 122, 131);
    margin: 0;
}

.ic-kaikas {
    background: url("images/ic-kaikas.webp") left top;
    width: 24px;
    height: 24px;
    margin-top: 0px;
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 12px;
}

.ic-metamask {
    background: url("images/ic-metamask.webp") left top;
    width: 24px;
    height: 24px;
    margin-top: 0px;
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 12px;
}

.ic-klip {
    background: url("images/ic-klip.webp") left top;
    width: 24px;
    height: 24px;
    margin-top: 0px;
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 12px;
}

.intro-banner {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 30vh;
}

.intro-banner img {
    margin-bottom: 40px;
}

.intro-banner .btn-klaytn-wallet {
    margin-top: 20px;
}

.intro-banner p {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.15px;
}

.container-main {
    padding: 80px 80px 0px 0px;
}

.container-main h1 {
    font-weight: 700;
    font-size: 56px;
    line-height: 140%;
    text-align: center;
}



.container-root .container-md {
    padding-top: 160px;
}

.container-reveal {
    background: inherit;
    background: rgba(255, 255, 255, 0.76);
    backdrop-filter: blur(40px);
    border-radius: 24px;
    padding-top: var(--p1);
    padding-left: var(--p1);
    padding-right: var(--p1);
    padding-bottom: var(--p1);
    font-size: var(--s2);
    font-weight: bold;
    text-align: center;
}

.container-reveal .title-img {
    width: 400px;
    margin-bottom: 40px;    
}

.qr-container {
    margin: 40px;
}

.snkrz-btn-normal {
    box-sizing: border-box;
    border: 1px solid #2C3036;
    border-radius: 8px;
    color: #2C3036;
    background: transparent;
    font-weight: 700;
}

.snkrz-btn-normal.dark {
    color: #ffffff;
    background: #2C3036;
}


@media (max-width: 484px) {
    .main-card {
	width: 100%;
	padding-top: var(--p6);
	padding-left: var(--p3);
	padding-right: var(--p3);
    }
    
    .container-md {
	padding: 0;
    }
    
    .intro-banner h2 {
	font-size: 36px;
	margin-bottom: 20px;
    }

    .intro-banner p {
	font-weight: 700;
	font-size: 18px;
	line-height: 140%;
	letter-spacing: 0.15px;
    }

    .container-main h1 {
	width: 100vw;
	font-size: 36px;
	line-height: 140%;
	text-align: center;
    }

    .container-main {
	padding: 20px 20px 0px 0px;
    }
}


/* radio */
.ic-radio-selected {
    background: url("images/ic_radio_selected.png") left top; 
    width: var(--s2);
    height: var(--s2);
    margin-top: 0px;
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: var(--s5);
}

.ic-radio-unselected {
    background: url("images/ic_radio_unselected.png") left top; 
    width: var(--s2);
    height: var(--s2);
    margin-top: 0px;
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: var(--s5);
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
