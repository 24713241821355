.container-actions .action-line {
    display: block;
    text-align: center;
}

.container-actions .action-line.selected .card {
    border: 1px solid #000000;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    position: relative;
}


.container-actions .action-line .card {
    display: inline-block;
    width: 50%;
    background: #FFFFFF;
    opacity: 0.7;
    border-radius: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.container-actions .action-line .radio {
    position: absolute;
    top: calc(50% - 12px);
    left: 16px;
}


.action-line h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.25px;
    color: #000000;
    margin: 0;
}

.action-line p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #555555;
    margin: 0;
}

.container-actions .snkrz-btn-normal {
    width: 235px;
    height: 56px;
}

.container-actions .new-tag {
    border-radius: 16px;
    background: red;
    color: white;
    font-size: 10px;
    padding: 4px 8px;
    position: absolute;
    top: 26px;
    right: 16px;
}


@media (max-width: 484px) {
    .title-img {
	width: 280px;
    }
    .container-reveal .title-img {
	width: 280px;
    }
    .container-actions .action-line .card {
	width: 100%;
    }
    .container-reveal {
	padding-left: 10px;
	padding-right: 10px;
    }
}
