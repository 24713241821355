.container-gallery {

}

.container-gallery .item-selected {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background: #E5EDF2;
    border-radius: 16px;
    position: relative;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
}

.item-selected img {
    width: 100%;
    border-radius: 16px;
}


.item-selected h3 {
    display: block;
    position: absolute;
    text-align: center;
    width: 100%;
    top: 45%;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #777777;
}

.container-gallery .reveal-button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    background: linear-gradient(94.25deg, #FF006E 0%, #3A86FF 100%);
    border-radius: 16px;
    font-weight: 700;
    font-size: 24px;
    color: #ffffff;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
}

.container-gallery .item-tab {
    background: #2C3036;
    border-radius: 16px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
}

.item-tab .tab-selected {
    width: 50%;
    height: 44px;
    background: #FFFFFF;
    border-radius: 14px;
    color: #22272E;
    border-color: #2C3036;
    font-weight: 700;
    font-size: 18px;
}

.item-tab .tab-unselected {
    width: 50%;
    height: 44px;
    background: #2C3036;
    border-radius: 14px;
    color: #FFFFFF;
    border-color: #2C3036;
    font-weight: 700;
    font-size: 18px;
}

.gallery-list {
    width: calc(100% - 20px);
    height: 0;
    padding-bottom: 100%;
    display: inline-block;
    border-radius: 16px;
    position: relative;
}

.gallery-list .plan-list {
    height: 250px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.gallery-list .reward-list {
    height: 250px;
    overflow-y: scroll;
    /* -ms-overflow-style: none;  */
    /* scrollbar-width: none; */
}

.gallery-list .nft-list {
    height: 550px;
    overflow-y: scroll;
}

.scroll-container {
    overflow: auto;
    padding-right: 5px;
    overflow-x: hidden;
}

.scroll-container.shink {
    height: 30px;
}

.scroll-container.expand {
    height: 470px !important;
}

.scroll-container::-webkit-scrollbar {
    width: 5px;
}
.scroll-container::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;
}
.scroll-container::-webkit-scrollbar-track {
    background-color: #FFFFFF;
}

.gallery-list::-webkit-scrollbar {
  display: none;
}

@media (max-width: 484px) {
    .scroll-container {
	height: 270px !important;
    }
    .gallery-list {
	padding-bottom: 0px;
    }
}

.gallery-item {
    display: block;
    position: relative;
    margin-bottom: 24px;
    width: 100%;
}

.gallery-item img {
    width: 100%;
    display: inline-block;
    background: #E5EDF2;
    border-radius: 16px;
    position: relative;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
}

.gallery-item .selected-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(44, 48, 54, 0.8);
    border: 2px solid #8FFF00;
    backdrop-filter: blur(4px);
    border-radius: 16px;
    top: 0;
}

.selected-cover img {
    background: transparent;
    width: 20px;
    height: 12px;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 6px);
}

.gallery-item .item-id {
    visibility: hidden;
    display: block;
    width: 100%;
    padding: 4px;
    position: absolute;
    bottom: 0;
    text-align: center;
    background: rgba(44, 48, 54, 0.8);
    backdrop-filter: blur(4px);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    text-align: center;
    letter-spacing: 0.15px;
    text-transform: uppercase;

    color: #FFFFFF;
    border-radius: 0px 0px 16px 16px;
}

.gallery-item .generation {
    display: block;
    padding: 4px;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    background: rgba(256, 256, 256, 0.3);
    backdrop-filter: blur(4px);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    text-align: center;
    letter-spacing: 0.15px;
    text-transform: uppercase;

    color: #FFFFFF;
    border-radius: 0px 16px 0px 16px;
}

.gallery-list a:hover .item-id {
    visibility: visible;
}

.item-desc {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 2px solid #777777;
    border-radius: 16px;
    padding: 16px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    margin-bottom: 10px;
}

.item-desc .desc {
    text-align: center;
}

.item-desc .desc .title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;

    letter-spacing: 0.15px;
    text-transform: uppercase;

    color: #777777;
}


.item-desc .desc .content {
    font-weight: 700;
    font-size: 15px;
    line-height: 140%;
    margin-left: 10px;

    letter-spacing: 0.15px;
    text-transform: uppercase;

    color: #111111;
}

.not-holder {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.not-holder img {
    width: 67px;
    height: 85px;
}

.not-holder h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #777777;
    margin-top: 30px;
}

.container-gallery .action-line .card {
    width: 100%;
}

.container-gallery .action-line.disabled .card {
    background: #E5EDF2;
}

.container-gallery .title {
    text-align: left;
    padding: 10px;
}

.container-gallery .reward-card {
    position: relative;
    border: 3px solid transparent;
    border-radius: 16px;
    background-image: linear-gradient(#E5EDF2, #E5EDF2),
		      linear-gradient(to right, #FF006E 0%, #3A86FF 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    margin-bottom: 16px;
    text-align: left;
}

.container-gallery .reward-card img {
    width: 64px;
    height: 64px;
    border-radius: 16px;
    margin-left: 16px;
    vertical-align: middle;
}

.container-gallery .reward-card .content {
    display: inline-block;
    vertical-align: middle;
    margin: 16px;
}

.container-gallery .reward-card h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #777777;
    text-align: left;
    margin: 0;
}

.container-gallery .reward-card p {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.15px;
    color: #000000;
    text-align: left;
    margin: 0;
}

.container-gallery .reward-card .reveal-button {
    position: absolute;
    top: calc(50% - 18px);
    right: 8px;
    width: 100px;
    height: 36px;
    background: linear-gradient(94.25deg, #FF006E 0%, #3A86FF 100%);
    border-radius: 53px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    /* or 25px */

    text-align: center;
    letter-spacing: 0.15px;
    text-transform: uppercase;

    color: #FFFFFF;
}

.container-gallery .reveal-button.disabled {
    border-color: transparent !important;
    background: #aaaaaa !important;
}

.fnf-plan-alert {
    font-size: 13px;
    text-align: right;
}
