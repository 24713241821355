.modal-reveal {
    padding: 40px 20px 40px 20px !important;
}

.modal-reveal .box {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.modal-reveal h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #111111;
    text-align: left;
}

.modal-reveal p {
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.15px;
    color: #444444;    
}

.modal-reveal-result {
    padding: 80px 10px 30px 10px !important;
}

.modal-reveal-result .box {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.modal-wallets .modal-content {
    width: 350px;
    display: block;
    text-align: center;
    border: none !important;
}

.modal-reveal-result .item-tag {
    background: linear-gradient(96.81deg, #FF39E4 0%, #4553FF 100%);
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 0px 10px 3px 10px !important;
    height: 60px;
    border-radius: 30px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
}


.modal-reveal-result .item-id {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.15px;
    text-transform: uppercase;

    color: #111111;    
}

.modal-reveal-result .ok-button {
    display: block;
    width: 320px;
    margin: 40px 15px 10px 15px;
    background: linear-gradient(94.25deg, #FF006E 0%, #3A86FF 100%);
    border-radius: 16px;
    font-weight: 700;
    font-size: 24px;
    color: #ffffff;
}

.modal-login .ok-button {
    display: block;
    width: 320px;
    margin: 40px 15px 10px 15px;
    background: linear-gradient(94.25deg, #FF006E 0%, #3A86FF 100%);
    border-radius: 16px;
    font-weight: 700;
    font-size: 24px;
    color: #ffffff;
}

.modal-login .send-email-button {
    width: 80px;
    height: 48px;
    right: 0px;
    top: 0px;
    position: absolute;
}


.modal-login .close-button {
    font-weight: 700;
    font-size: 16px;
    background: none;
    border-color: transparent !important;
    color: #000000;
    position: absolute;
    top: 0;
    right: 0;
}


.modal-login .email-input-container {
    position: relative;
    padding-right: 86px;
    padding-bottom: 16px;
}

.modal-login .verification-timer {
    padding: 14px 10px 14px 10px;
    color: #FF4E4E;
    position: absolute;
    top: 0;
    right: 0;
    gravity: center;
    font-size: 13px;
    visibility: hidden;
}

.modal-login .code-input-container {
    position: relative;    
}

.hidden-button {
    visibility: hidden;
}

.rare .item-tag { 
    background: linear-gradient(96.81deg, #38FEFF 0%, #CFFF54 100%);
    color: black;
}

.normal .item-tag {
    background: #E4EFFF;
    color: black;
}

.modal-container {
    padding: 24px;
}

.modal-logout {
    width: 350px;
}

.modal-logout .snkrz-btn-normal {
    width: calc(50% - 10px);
    margin: 5px;
}

.modal-container .plan-warn {
    padding: 8px;
    width: 350px;
    margin-top: 16px;
    border-radius: 4px;
    border: 1px solid #E6EEF2;
}
